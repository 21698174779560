<template>
  <div class="wrapper d-flex align-items-center justify-content-center">
    <VueLoading v-model:active="isLoading" color="#196bb0"></VueLoading>
    <div class="inner d-flex flex-column justify-content-between w-100 py-5">
      <div class="text-center mb-5">
        <h1>精準營養管理資訊系統</h1>
        <h2>Information system of precision nutrition management(ISOPNM)</h2>
      </div>
      <button class="btn btn-primary" @click.prevent="googleSignIn">Google 登入</button>
    </div>
  </div>
</template>

<script>
// 載入 Vue 3 composition api
import { ref } from 'vue';
// 載入 vue router
import { useRouter } from 'vue-router';
// 載入 loading overlay
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// 載入 firebase 需要的工具
// eslint-disable-next-line object-curly-newline
import { getAuth, signInWithRedirect, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';

export default {
  components: {
    VueLoading,
  },
  setup() {
    const userUid = ref('');
    const isLoading = ref(true);
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    const router = useRouter();

    function googleSignIn() {
      signInWithRedirect(auth, provider);
    }

    // 檢查使用者登入狀態
    onAuthStateChanged(auth, (user) => {
      if (user) {
        isLoading.value = false;
        userUid.value = user.uid;
        router.push('/');
      } else {
        isLoading.value = false;
      }
    });

    return {
      userUid,
      isLoading,
      googleSignIn,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  &::before {
    content: '';
    background-image: url('/img/login.jpg');
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
    z-index: -1;
  }
}
.inner {
  color: $primary;
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
